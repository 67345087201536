import "../scss/your-account.scss";

const accountLinks = document.querySelectorAll(".your-acct-links");

if (accountLinks.length > 0) {
    displaySection(sessionStorage.getItem("acct-tab") ?? "donations");

    document.querySelector(".account-switchers")!.addEventListener("click", (event: Event) => {
        const acctButton = event.target as HTMLButtonElement;

        if (!acctButton.classList.contains("your-acct-links")) {
            return;
        }

        // The id is the section title
        displaySection(acctButton.id);
    });
}

/**
 * Display the "your account" section
 */
function displaySection(section = "donations") {
    // Check for local storage value, if no value then default to donations tab
    if (section === "sign-out" || section === "hosp-admin") {
        return;
    }

    const currentSection = document.getElementById(`${section}-section`);

    // Set session storage so we can load the correct tab on page refreshes
    sessionStorage.setItem("acct-tab", section);

    for (const acctSection of document.querySelectorAll(".section-select")) {
        acctSection.classList.remove("selected");
    }

    for (const acctSection of document.querySelectorAll(".sections")) {
        acctSection.classList.remove("show-section");
    }

    document.getElementById(section)!.classList.add("selected");
    currentSection?.classList.add("show-section");

    import(`./modules/account/${section}`);
}
